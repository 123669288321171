@import url(https://fonts.googleapis.com/css?family=Roboto:regular,500);

body {
  font-family     : 'Roboto', Arial, sans-serif;
  background-color: #222f3e;
  color           : #eee;
}

.app {
  padding-top    : 5rem;
  display        : flex;
  justify-content: center;
}

.wrap {
  width: 500px;
}

$height: 46px;

.select {
  width   : 100%;
  position: relative;
  z-index : 100;

  &.open {
    .select__dropdown {
      display: block;
    }

    .select__input {
      border-bottom: none;
    }

    .select__backdrop {
      display: block;
    }
  }

  &__input {
    font-weight    : 500;
    border         : 1px solid #3498db;
    border-radius  : 5px;
    height         : $height;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 0 1rem;
    transition     : .15s background-color ease-in;

    &:hover {
      background-color: #1e90ff;
      transition      : .15s background-color ease-in;
      cursor          : pointer;
    }

    .fa {
      transition: .05s ease-in;
    }
  }

  &__dropdown {
    display      : none;
    position     : absolute;
    border       : 1px solid #2980b9;
    border-radius: 5px;
    top          : $height;
    left         : 0;
    right        : 0;
    max-height   : 200px;
    overflow-y   : auto;
    box-shadow   : 2px 3px 5px rgba(0, 0, 0, .3);
    border-right : none;
    transition   : .1s background-color ease-in;
  }

  &__list {
    padding   : 0;
    margin    : 0;
    list-style: none;
  }

  &__backdrop {
    position  : fixed;
    display   : none;
    z-index   : -1;
    top       : 0;
    right     : 0;
    left      : 0;
    bottom    : 0;
    background: transparent;
  }

  &__item {
    padding      : 1rem;
    border-bottom: 1px solid #111;
    transition   : .1s background-color ease-in;

    &.selected {
      background-color: rgba(0, 0, 0, .5);
    }

    &:hover {
      background-color: rgba(0, 0, 0, .2);
      cursor          : pointer;
    }
  }

  // Custom scrollbar for Blink- and WebKit-based browsers (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS, and others)
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: #4b6584;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #1e90ff;
    border-radius   : 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #1B9CFC;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #3fbfff;
  }

  // Custom scrollbar for Firefox, Edge, IE
  scrollbar-color : #1e90ff #4b6584;
}