@import "https://fonts.googleapis.com/css?family=Roboto:regular,500";
body {
  font-family: "Roboto", Arial, sans-serif;
  background-color: #222f3e;
  color: #eee;
}
.app {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
}
.wrap {
  width: 500px;
}
.select {
  width: 100%;
  position: relative;
  z-index: 100;
  scrollbar-color: #1e90ff #4b6584;
}
.select.open .select__dropdown {
  display: block;
}
.select.open .select__input {
  border-bottom: none;
}
.select.open .select__backdrop {
  display: block;
}
.select__input {
  font-weight: 500;
  border: 1px solid #3498db;
  border-radius: 5px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  transition: 0.15s background-color ease-in;
}
.select__input:hover {
  background-color: #1e90ff;
  transition: 0.15s background-color ease-in;
  cursor: pointer;
}
.select__input .fa {
  transition: 0.05s ease-in;
}
.select__dropdown {
  display: none;
  position: absolute;
  border: 1px solid #2980b9;
  border-radius: 5px;
  top: 46px;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
  border-right: none;
  transition: 0.1s background-color ease-in;
}
.select__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.select__backdrop {
  position: fixed;
  display: none;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
}
.select__item {
  padding: 1rem;
  border-bottom: 1px solid #111;
  transition: 0.1s background-color ease-in;
}
.select__item.selected {
  background-color: rgba(0, 0, 0, 0.5);
}
.select__item:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.select ::-webkit-scrollbar {
  width: 20px;
}
.select ::-webkit-scrollbar-track {
  background-color: #4b6584;
}
.select ::-webkit-scrollbar-thumb {
  background-color: #1e90ff;
  border-radius: 10px;
}
.select ::-webkit-scrollbar-thumb:hover {
  background-color: #1B9CFC;
}
.select ::-webkit-scrollbar-thumb:active {
  background-color: #3fbfff;
}
/*# sourceMappingURL=index.dd0ef68a.css.map */
